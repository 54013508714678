<script>
import consolesService from '@/services/consoles.service'
import libraryService from '@/services/library.service'
import Category from '@/data/models/Category'
import EditLayout from '@/layout/EditLayout'

export default {
  name: 'SingleConsoleCategory',
  components: {
    EditLayout
  },
	data() {
		return {
      item: new Category({ type: 'console' }),
      photo: null,
		}
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    isNew() {
      return this.id === 'new'
    },
  },
	mounted() {
    !this.isNew && this.id && this._get()
	},
	methods: {
    _get(type = null) {
      consolesService.getCategoryById({ id: this.id })
      .then(res => {
        this.item = res.data
        res.data.library_id && this._getPhoto({ id: res.data.library_id })
      })
    },
    _getPhoto({ id }) {
      libraryService.getById({ id })
        .then(res => {
          this.photo = res.data
        })
    },
    create() {
      consolesService.createCategory({ data: this.item })
        .then(_res => {
          this.$notify({
            message: this.$t('m.itemSuccessfullyAdded', { item: this.$tc('consoleCategory') }),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
          this.$router.push({ name: 'consoleCategories' })
        })
    },
    edit() {
      consolesService.editCategory({ data: this.item })
        .then(_res => {
          this.$notify({
            message: this.$t('m.itemSuccessfullySaved', { item: this.$tc('consoleCategory') }),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
        })
    },
	}
}
</script>

<template>
  <EditLayout 
    supports-photo
    :item="item"
    :submit-button-text="isNew ? $t('create') : $t('save')"
    :photo="photo"
    @submit="isNew ? create() : edit()"
  />
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

</style>
